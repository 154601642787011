







































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { categoryService } from "@/services/category.service";

@Component
export default class Categories extends Vue {
  // Data
  categoryName = "";
  categoryDescription = "";
  visualSelectionStyle = "Polygons";

  valid = false;
  rules = {
    categoryName: [(v: String) => !!v || "Category name is required"]
  };
  categoryCreated = false;

  // Methods

  createCategory() {
    categoryService
      .addCategory(
        this.categoryName,
        this.categoryDescription,
        this.visualSelectionStyle
      )
      .then(() => {
        this.categoryCreated = true;
        this.$router.go(0);
      });
  }

  submit() {
    this.createCategory();
  }
}
